import { PortableTextBlock } from "@portabletext/types"
import { captureMessage } from "@sentry/nextjs"
import type { IconType } from "components/Icon"
import { get, HttpError } from "lib/http/http"

export type PaywallType = "not-logged-in" | "no-subscription" | "none"

export interface ITemplate {
    name: string
    hasNavigation: boolean
    hasTools: boolean
    showNonSubscriberInfo: boolean
}
export interface IGalleryImage {
    index: number
    src: string
    alt?: string
    caption?: string
    title?: string
}

export interface ISection {
    url: string
    type: string
    title: string
    name: string
    heading: PortableTextBlock[]
    collapsed: boolean
    hasReadAccess: string[]
    blockContent: PortableTextBlock[]
    sections: ISection[]
    isPreview?: boolean
}

export interface IAuthor {
    current: boolean
    name: string
    organization: string
    title: string
}
export interface ILinkListMore {
    href: string
    text: string
    title: string
    target: string
}

export interface ILinkListLink {
    href: string
    text: string
    title: string
    target: string
}

export interface ILinkList {
    name: string
    type: string
    icon: IconType
    more: ILinkListMore
    links: ILinkListLink[]
}

export interface IReferenceLink {
    href: string
    text: string
    title: string
    target: string
}

export interface IReference {
    referenceId: string
    text: string
    link: IReferenceLink
}

export interface IUpdate {
    expires: Date
    name: string
    owner: string
    published: Date
    text: string
    type: string
}

export interface IPage {
    contentGuid: string
    contentId: number
    name: string
    title: string

    url: string
    shortcut?: string
    template?: ITemplate
    metadata?: {
        title: string
        description: string
    }
    types: string[]
    hasReadAccess: string[]
    labels: string[]
    tags: string[]
    blockContent: PortableTextBlock[]

    updated?: Date
    revised?: Date
    published?: Date
    unpublished?: Date
    revisionInterval: number

    updates: IUpdate[]
    parents: IPage[] // Breadcrumb
    siblings?: IPage[] // Sitelist left aside
    children?: IPage[] // Sitelist content

    gallery: IGalleryImage[]
    linkLists?: ILinkList[]
    sections?: ISection[]

    sources?: ISection
    authors?: IAuthor[]
    references?: IReference[]

    noIndex?: boolean
    ingress?: string
    byline?: string

    image?: {
        alt?: string
        caption?: string
        src: string
        width: number
        height: number
    }
    qrCodeUrl?: string
    includeAuthorText?: boolean
    hasImportantUpdateUntil?: Date

    source?: {
        name: string
        url: string
    }
}

/**
 * Endpoint for fething all data, except block content
 * @param path path to the page... what did you expect ?
 * @returns page without block content
 */
export async function getPage(path?: string): Promise<IPage | null> {
    if (!path) {
        return null
    }

    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        "/Content/Page?path=" +
        encodeURIComponent(path)

    const { data } = await get<IPage | null>(endpoint, undefined).catch(
        (httpError: HttpError) => {
            if (httpError.response?.status == 404) return { data: null }
            else throw httpError
        }
    )
    return data
}

/**
 * Protected endpoint for fetching all data for a page
 * @param path path to the page... what did you expect ?
 * @param token access token
 * @returns page with block content
 */
export async function getPageProtected(
    path: string,
    token: string | null | undefined
): Promise<IPage | null> {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        "/Content/ProtectedPage?path=" +
        encodeURIComponent(path)

    const { data } = await get<IPage>(endpoint, token)
    return data
}

/**
 * Internal endpoint for fetching all data for a page. Used for organisations and medibas.pl
 * @param path path to the page... what did you expect ?
 * @returns page with block content
 */
export async function getPageProtectedInternal(path: string) {
    const { data } = await get<IPage>(`/api/content?url=${path}`)

    return data
}

export type CardColorType =
    | "default"
    | "primary"
    | "secondary"
    | "grey"
    | "white"

export interface IFrontpage extends IPage {
    main: IFrontpageCard[]
}

export interface IFrontpageCard {
    contentId: number
    url: string
    types: string[]
    icon: string
    image?: {
        src: string
        width: number
        height: number
    }
    imageType: string
    alignment: string
    preTitle?: string
    title?: string
    blockContent: PortableTextBlock[]
    color: CardColorType
    allowedRoles: string[]
    columns: number
    unpublished?: Date

    more: {
        href: string
        text: string
        title: string
        target: string
    }
    hasReadAccess: string[]
    cards: IFrontpageCard[]

    // UpdatesCard props
    take: number
    templateGroup: string
    includeNew: boolean
    includeUpdated: boolean
    includeRevised: boolean

    // ArticleUpdatesCard (incl. take)
    onlyImportant: boolean
    displayDate: boolean
}

/**
 * Get the frontpage
 * @returns List of IFrontpageCards
 */
export async function getFrontpage(): Promise<IFrontpage | null> {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        "/Content/Page?path=" +
        encodeURIComponent("/")

    const { data } = await get<IFrontpage | null>(endpoint, undefined).catch(
        (httpError: HttpError) => {
            if (httpError.response?.status == 404) return { data: null }
            else throw httpError
        }
    )
    return data
}

export interface IFooterCard extends IFrontpageCard { }

/**
 * Get footer content or throw exception and send to sentry
 * @returns List of IFooterCards
 */
export async function getFooterCards(): Promise<IFooterCard[]> {
    const endpoint = process.env.NEXT_PUBLIC_CONTENT_API + "/Content/Footer"
    try {
        const { data } = await get<IFooterCard[]>(endpoint)
        if (data) {
            return data
        }
    } catch (error) {
        captureMessage(
            "Error fetching footer from content-api: " + endpoint,
            "error"
        )
    }
    return []
}

/**
 * Fetch preview of page
 * @param path Path for page
 * @param token Access token
 * @returns Preview version of page
 */
export async function getPreview(path: string, token: string) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API + `/Content/Preview?path=${path}`
    const { data } = await get<IPage | null>(endpoint, token)
    return data
}

export interface IArticleUpdate {
    contentId: number
    name: string
    published: Date
    text: string
    owner: {
        name: string
        url: string
    }
}

/**
 * Fetch list of updates
 * @param take Number of update to take
 * @param onlyImportant Only get important updates
 * @param onlyCurrent Only get current updates
 * @returns List of updates
 */
export async function getUpdates(
    skip = 0,
    take: number,
    onlyImportant: boolean,
    onlyCurrent: boolean
) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        `/Content/Updates?skip=${skip}&take=${take}&onlyImportant=${onlyImportant}&onlyCurrent=${onlyCurrent}`
    const { data } = await get<IArticleUpdate[]>(endpoint)
    return data ?? []
}

export interface INewPages {
    title: string
    url: string
    timestamp: Date
}
/**
 * Fetch list of pages that has been created since a given date(default to last 30 days)
 * @param since Date since (get pages created since this date, eks: 2021-2-2)
 * @param type Type of page (public or professional)
 * @returns List of pages
 */

export async function getNewPages(since: string | undefined, type: string) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        `/Content/New?since=${since}&type=${type}`
    const { data } = await get<INewPages[]>(endpoint)
    return data ?? []
}

export interface IMegamenuNode {
    url: string
    name?: string
    icon?: string
    type?: string
    viewType?: string
    nodes?: IMegamenuNode[]
}

export interface IMegaMenu {
    name?: string
    nested?: boolean
    children: IMegaMenu[]
    viewType?: string
    icon?: string
    professionalContent?: boolean
    noAdverts?: boolean
    types?: string[]
    contentId?: number
    url?: string
}

function megamenuAdapter(megamenu: IMegaMenu): IMegamenuNode {
    const { url, name, icon, types, viewType, children } = megamenu

    const adapted = {
        url: url ?? "",
        name,
        icon,
        type: types ? types[types.length - 1] : undefined,
        viewType,
        nodes: children
            ? children.map(child => megamenuAdapter(child))
            : undefined
    }

    for (const [key, value] of Object.entries(adapted)) {
        if (value === undefined) delete adapted[key as keyof IMegamenuNode]
    }

    return adapted
}

/**
 * Load megamenu or throw exception and send to sentry
 * @returns IMegamenuNode
 */
export async function getMegamenu(): Promise<IMegamenuNode> {
    const endpoint = process.env.NEXT_PUBLIC_CONTENT_API + "/Content/Megamenu"
    let megamenu: IMegamenuNode = { url: "", nodes: [] }

    try {
        const { data } = await get<IMegaMenu[] | null>(endpoint)
        if (!data) {
            return megamenu
        }

        megamenu = megamenuAdapter((data as IMegaMenu[])[0])
    } catch (error) {
        captureMessage(
            "Error fetching megamenu from content-api: " + endpoint,
            "error"
        )
    }
    return megamenu
}
