import useGetAccessInfo from "hooks/useGetAccessInfo"
import useUser from "hooks/useUser"
import { useQuery } from "react-query"
import { OptionsType } from "types/shared.types"

import {
    IArticleUpdate,
    IFooterCard,
    IMegamenuNode,
    INewPages,
    IPage,
    getFooterCards,
    getMegamenu,
    getNewPages,
    getPage,
    getPageProtected,
    getPageProtectedInternal,
    getUpdates
} from "data/contentData/api/content"
import isEnabled from "lib/is-enabled"

export enum ContentCacheKeys {
    PAGES = "pages",
    PAGES_PROTECTED = "pages-protected",
    PAGES_PROTECTED_INTERNAL = "pages-protected-internal",
    PAGES_EDITORIAL = "pages-editorial",
    FOOTER = "footer",
    ARTICLE_UPDATES = "article-updates",
    NEW_PAGES = "new-pages",
    MEGAMENU = "megamenu"
}

/**
 * Get public section of page.
 * @param options
 * @returns
 */
export function useGetPagePublic(
    path: string,
    options?: OptionsType<IPage | null>
) {
    return useQuery<IPage | null, Error>(
        [ContentCacheKeys.PAGES, path],
        async () => await getPage(path),
        {
            ...options
        }
    )
}

/**
 * Get protected (authorized) page by providing URL
 * @param url
 * @param options
 * @returns
 */
export function useGetPageProtected(
    url: string,
    options?: OptionsType<IPage | null>
) {
    const { authenticated, accessToken, isFetching } = useUser()

    return useQuery<IPage | null, Error>(
        [ContentCacheKeys.PAGES_PROTECTED, url],
        async () => await getPageProtected(url, accessToken),
        {
            ...options,
            enabled: isEnabled(authenticated && !isFetching, options)
        }
    )
}

type FetchType = "internal" | "protected" | "none"

/**
 * Check initial page data and access info to determine how to fetch data
 * @param initialData
 * @returns how to fetch data for page
 */
function useGetFetchType(initialData: IPage): FetchType {
    const { authType, authorized } = useGetAccessInfo(initialData)

    const url = initialData?.url
    if (!Boolean(url)) {
        return "none"
    }

    const isPublic =
        initialData?.hasReadAccess.includes("Everyone") ||
        initialData?.hasReadAccess.length === 0

    // No need to fetch data, they should be available in the initialData
    if (isPublic) {
        return "none"
    }

    // Use internal API to fetch data for medibas.pl
    if (authorized && process.env.NEXT_PUBLIC_ID === "medibas-pl") {
        return "internal"
    }

    // Use internal API to fetch data for organisations
    if (authorized && authType === "organisation") {
        return "internal"
    }

    // Use protected API to fetch data for users
    if (authorized && authType === "user") {
        return "protected"
    }

    return "none"
}

/**
 * Get protected (authorized) props for page
 * Use internal API to fetch data for organisations & medibas.pl
 * Users and organisations need active subscriptions to access content
 * @param initialData
 * @returns Page with protected content
 */
export function useGetPage(initialData: IPage) {
    const { accessToken } = useUser()
    const url = initialData?.url
    const fetchType = useGetFetchType(initialData)

    const fetcher =
        fetchType === "internal"
            ? () => getPageProtectedInternal(url)
            : () => getPageProtected(url, accessToken)

    // Fetch protected content via internal API
    const { data: protectedData } = useQuery<IPage | null, Error>(
        [ContentCacheKeys.PAGES_PROTECTED, url],
        fetcher,
        {
            enabled: fetchType === "internal" || fetchType === "protected"
        }
    )

    const page = {
        ...initialData,
        ...protectedData
    }

    return page
}

export function useGetArticleUpdates(
    skip: number,
    take: number,
    onlyCurrent: boolean,
    onlyImportant: boolean,

    options?: OptionsType<IArticleUpdate[]>
) {
    return useQuery<IArticleUpdate[], Error>(
        [ContentCacheKeys.ARTICLE_UPDATES, skip],
        async () => await getUpdates(skip, take, onlyImportant, onlyCurrent),
        {
            ...options
        }
    )
}

export function useGetNewPages(
    since: string | undefined,
    type: string,
    options?: OptionsType<INewPages[]>
) {
    return useQuery<INewPages[], Error>(
        [ContentCacheKeys.NEW_PAGES],
        async () => await getNewPages(since, type),
        {
            ...options
        }
    )
}

export function useGetMegamenu(options?: OptionsType<IMegamenuNode>) {
    return useQuery<IMegamenuNode, Error>(
        ContentCacheKeys.MEGAMENU,
        async () => await getMegamenu(),
        { ...options, staleTime: 1000 * 60 * 60 * 24 }
    )
}

export function useGetFooterCards(options?: OptionsType<IFooterCard[]>) {
    return useQuery<IFooterCard[], Error>(
        ContentCacheKeys.FOOTER,
        async () => await getFooterCards(),
        { ...options, staleTime: 1000 * 60 * 60 * 24 }
    )
}
