import { auth } from "config/config"
import useUser from "hooks/useUser"
import { useQuery } from "react-query"

import { IOrganisationResponse } from "data/customerManager/api/organisation"
import isAuthorized from "lib/auth"
import { get } from "lib/http/http"

const OrganisationCacheKeys = {
    ORGANISATION_IP: "organisation-ip"
}

/**
 * Get organisation from "local" api endpoint
 * @returns Organisation if it exists.
 */
async function getIPUser() {
    try {
        const { data } = await get<IOrganisationResponse | null>("/api/ip-user")
        return data
    } catch (_) {
        return null
    }
}

/**
 * Gets an organisation via IP / User organisation affiliation (implicit on api endpoint)
 * @returns
 */
export function useGetOrganisation() {
    const { user, isFetching: isFetchingUser } = useUser()

    // Enable query if the following conditions are met
    // - CDS has IP access enabled in config
    // - user is not subscriber
    // - is not fetching user
    const enabled =
        auth.hasIPAccess &&
        !isAuthorized(user?.roles, auth.roles.subscriber) &&
        !isFetchingUser

    const { data, isLoading } = useQuery<IOrganisationResponse | null, Error>(
        [OrganisationCacheKeys.ORGANISATION_IP],
        async () => await getIPUser(),
        {
            enabled,
            retry: false
        }
    )

    let roles: string[] = []
    if (data) {
        roles =
            data.claims
                ?.filter(
                    p =>
                        p.key ===
                        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                )
                .map(p => p.value) ?? []
    }

    const result = {
        ...data,
        roles,
        authenticated: !isLoading && !!data,
        isLoading
    }

    return result
}
